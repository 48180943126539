.main-back {
  display: flex;
  //   justify-content: center;
  //   align-items: center;
  min-height: 400px;
  background-color: #000;
}

.detail-container {
  // max-width: 1200px;
  width: 100%;
  position: relative;
}

.image-section {
  position: relative;
}

.image-section img {
  height: 400;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: flex-start;
  padding: 20px 60px;
  background: rgba(0, 0, 0, 0.5);
}

.movie-info {
  max-width: 40%;
  color: #fff;
  padding-bottom: 3rem;
}

.rating {
  background-color: #666;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 10px;
}

// .movie-info h3 {
//   font-size: 2.5rem;
//   margin-bottom: 10px;
// }

.movie-info p {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.ticket-button {
  background-color: #fed530;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  //   margin-bottom: 20px;
  border-radius: 40px;
}

.icons {
  display: flex;
  gap: 10px;
}

.icon-button {
  color: #fff;
  font-size: 1.5rem;
  background: #1e1e1e;
  border: none;
  gap: 5px;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button:hover {
  background: #1e1e1e;
  color: #fed530;
}

.icon-button:active {
  background: #1e1e1e;
  color: #fed530;
}

.Butons-moredetails {
  display: flex;
}
.custom-carousel .carousel-item img {
  //   width: 1440px !important;
  // height: 540px !important;
  object-fit: cover;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner-border {
  color: #fed530;
}

.d-block {
  height: auto;
}

@media (max-width: 480px) {
  .main-back {
    min-height: 271px !important;
  }
  .overlay {
    padding: 1rem;
    padding-top: 12rem;
  }
  .movie-info {
    padding: 0;
    max-width: 100%;
  }
  .d-block {
    height: 300px;
  }
  .custom-carousel .carousel-item img {
    height: 300px;
  }
  .ticket-button {
    font-size: 16px;
    height: 40px;
  }
  .icon-button{
    height: 40px;
    width: 40px;
  }
}
