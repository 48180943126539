.analytics-container {
  padding: 2rem;
  background-color: black;
  color: #ffffff;
  font-family: Arial, sans-serif;
  max-width: 100%; /* Set a max-width for large screens */
  margin: 0 auto; /* Center the container */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Ensure wrapping on smaller screens */
}

.title h2 {
  margin: 0;
  font-size: 1.5rem;
}

.title p {
  margin: 0;
  font-size: 1rem;
  color: #cccccc;
}

.filter-button {
  display: flex;
  background-color: #333333;
  color: #ffffff;
  border-radius: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 15px;
  text-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.filter-button span {
  flex-grow: 1;
  text-align: left;
}

.chart-container {
  background-color: #161616;
  padding: 1rem;

  margin-top: 2rem; /* Add margin for better spacing */
}

.total-watch-hours-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  border-bottom: 2px solid #333333;
  padding-bottom: 1rem;
  flex-wrap: wrap; /* Ensure wrapping on smaller screens */
}

.total-watch-hours {
  align-items: baseline;
  flex: 1; /* Allow it to take up available space */
}

.total-watch-hours p {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.total-watch-hours h1 {
  font-size: 2rem;
}

.watch-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1; /* Allow it to take up available space */
}

.watch-details p {
  margin: 0;
  font-size: 1rem;
}

.last-days {
  margin: 2rem 0;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .title h2 {
    font-size: 1.25rem;
  }

  .title p {
    font-size: 0.875rem;
  }

  .filter-button {
    margin-top: 0.5rem;
    padding: 0.5rem;
  }

  .total-watch-hours p {
    font-size: 1.25rem;
  }

  .total-watch-hours h1 {
    font-size: 1.75rem;
  }

  .watch-details p {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .title h2 {
    font-size: 1rem;
  }

  .title p {
    font-size: 0.75rem;
  }

  .filter-button {
    margin-top: 0.5rem;
    padding: 0.5rem;
  }

  .total-watch-hours p {
    font-size: 1rem;
  }

  .total-watch-hours h1 {
    font-size: 1.5rem;
  }

  .watch-details p {
    font-size: 0.75rem;
  }
}
