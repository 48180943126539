.contact-body {
  background-color: black !important;
}

.contact-title {
  font-family: "DM Sans";
  font-size: 32px;
  font-weight: 700;
  line-height: 41.66px;
  text-align: left;
  margin-bottom: 10px !important;
}

.contact-sub-title {
  font-family: "DM Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 23.44px;
  text-align: left;
  margin-bottom: 10px !important;
}

.contact-card {
  margin-top: 20px;
  background-color: #1e1e1e !important;
  border-radius: 10px !important;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.contact-button {
  width: 145px;
  height: 44px;
  padding: 10px 16px 10px 20px;
  gap: 6px;
  border-radius: 45px !important;
  background-color: #fed530 !important;
  color: #111111 !important;
  text-wrap: nowrap;
}

@media (max-width: 500px) {
  .contact-title {
    font-size: 24px !important;
  }

  .contact-sub-title {
    font-size: 16px !important;
  }
}

// Faq page css
.faq_card {
  margin-top: 20px;
  background-color: #1D1E1E !important;
  border-radius: 8px !important;
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.faq-button {
  width: 145px;
  height: 44px;
  padding: 10px 16px 10px 20px;
  gap: 6px;
  border-radius: 45px !important;
  background-color: #fed530 !important;
  color: #111111 !important;
  text-wrap: nowrap;
  margin-top: 100px;
}

.accordion_text1 {
  color: var(--Text-White, #FFF);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.accordion_text2 {
  color: rgba(255, 255, 255, 0.60);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.faq-card .MuiSvgIcon-root {
  color: white;
}

// Screen1 page css
.screenPara1 {
  color: #FED530;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}

.screenPara2 {
  color: var(--Text-White, #FFF);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}

.screenPara3 {
  color: #E6E6E6;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.screen-body {
  background: linear-gradient(270deg, rgba(13, 13, 13, 0.00) 15.98%, #0D0D0D 56.86%), no-repeat;
}