.buy_modal .modal-content {
    background: #1E1E1E;
    color: #fff;
    padding: 8px;
}
 
.screenParas {
    color: #FED530;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: "DM Sans";
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
}
 
.screenParas2 {
    color: #FFF;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
}
 
.screenParas3 {
    color: #FFF;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
}
 
.buybutton {
    background-color: #fed530;
    border-radius: 40px;
    color: #1e1e1e;
    padding: 0px 24px;
    color: #000;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
}
 
.screenParas4 {
    /* padding-left: 20px; */
}
 
.prize {
    border-bottom: 1px dashed #fed530;
}
 
.amount {
    padding-left: 10px;
}