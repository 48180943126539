// src/scss/Transactions.scss
.transactions {
  .upload-movie-tex {
    font-size: 20px;
    font-weight: 700;
    line-height: 26.04px;
    text-align: left;
    margin: 1rem 0;
  }
  .upload-movie {
    background-color: #fed530;
    color: black !important;
    border-radius: 45px;
    min-height: 44px;
    width: 200px;
  }
  .upload-movie:hover {
    background-color: #fed530;
  }
  .transaction-card {
    display: flex;
    align-items: center;
    height: 120px;
    background-color: #181818;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;

    .transaction-image-rental {
      height: 100px;
      width: 70px;
      object-fit: cover;
      border-radius: 4px;
    }

    .transaction-image-subscribtion {
      height: 100px;
      width: 70px;
      object-fit: contain;
      border-radius: 4px;
    }

    .transaction-image {
      height: 100px;
      width: 70px;
      object-fit: cover;
      border-radius: 4px;
    }

    .transaction-info {
      flex-grow: 1;
      margin-left: 10px;
      color: white;

      .rent-label {
        background-color: #fed530;
        color: black;
        font-weight: bold;
        padding: 2px 4px;
        border-radius: 2px;
        display: inline-block;
      }

      .transaction-title {
        margin-top: 5px;
        font-weight: bold;
      }

      .transaction-date {
        margin-top: 5px;
        color: #b0b0b0;
      }
    }

    .transaction-arrow {
      color: #b0b0b0;
    }
  }
}
.Transactions-heading {
  margin-bottom: 3rem;
}
