/* TermsAndConditions.css */

.terms-container {
  background-color: black;
  color: white;
  padding: 20px;
  margin: 20px;
}

.terms-header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.terms-content {
  font-size: 1rem;
  line-height: 1.5;
}

.terms-section {
  margin-bottom: 20px;
}

@media (max-width: 500px) {
  .terms-container {
    padding: 0 !important;
    margin: 0 !important
  }
  .terms-header {
    font-size: 24px !important;
    margin-bottom: 0.2rem;
  }
  .terms-content {
    font-size: 16px !important;
  }
  
}
