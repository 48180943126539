/* EmptyState.scss */

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 89vh;
  background-color: black;
  color: white;
  text-align: center;

  .empty-state-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    h2 {
      font-size: 2em;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.2em;
    }
  }
}
 .Continue-Button-intropage {
    border-radius: 40px;
    background: #FED530;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    float: right;
    padding: 16px 30px;
    justify-content: center;
    gap: 8px;
    color: #1E1E1E;
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 500;
  }
