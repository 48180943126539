.mobNum {
  color: #fff !important;
}

.mob-input {
  border-radius: 45px !important;
}

.google {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(0, 0, 0) !important;
  height: 50px !important;
  width: -webkit-fill-available !important;
  margin-top: 25px !important;
  cursor: pointer !important;
}

.facebook {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(0, 0, 0) !important;
  height: 50px !important;
  width: -webkit-fill-available !important;

  cursor: pointer;

  margin-top: 15px !important;
}

img {
  width: -webkit-fill-available;
}

.css-a1xfks {
  position: relative;
}

.cardvalue-otp {
  display: contents;

  .login-Header-otp {
    color: #fed530;
    text-align: left;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "DM Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
    // display: "flex";
    // justify-content: "center";
    margin-top: 50px;
  }
}

.skip-otp {
  background-color: #ffffff8a;
  float: left;
  border-radius: 25px;
  opacity: 0.6;
  cursor: pointer;
  height: 44px;

  p {
    margin-top: 1px;
  }
}

.full-page-background-producer {
  top: 0;
  left: 0;
  background-color: #000;
  z-index: -1;
  border: 2px solid black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // background: linear-gradient(
  //     0deg,
  //     rgba(0, 0, 0, 0.8) 0%,
  //     rgba(0, 0, 0, 0.8) 100%
  //   ),
  //   url("../images/ott.png");
  background: #151512;
  // background:  #161616;
  height: 88vh;
  max-height: 87vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Continue-Button-producer {
  background-color: #fed530 !important;
  color: #101828 !important;
  border: none;
  border-radius: 45px !important;
  cursor: pointer !important;
  font-size: 20px !important;
  width: -webkit-fill-available !important;
  font-weight: bold !important;
  padding: 16px 0px !important;
  font-family: "DM Sans";
  font-weight: 500 !important;
}

.otp {
  //   display: flex;
  //   justify-content: center;
  text-align: center;
}

.timer {
  margin-top: 20px;
  font-size: 18px;
  color: #d86d6d;
  display: flex;
  justify-content: center;
}

.Resend-otp {
  margin-top: 5px;

  p {
    display: flex;
    justify-content: center;
    color: #fff;
    margin-top: "25px";
  }
}

input {
  margin: 0 5px;
}

.form-control-1 {
  background: #000 !important;
  padding: 9px 24px 9px 16px;
  // width: 94% !important;
  height: 52px !important;
  color: #fff !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: none !important;
  text-align: start !important;
  border: 1px solid white !important;
  border-radius: 6px !important;
  margin: 0;
}

.form-control-1:focus {
  border: 1px solid #fed530 !important;
}

.file-input-producer {
  background: transparent !important;
  color: #fff;
}

.form-control[type=file] {
  height: 27px !important;
  padding-top: 0px !important;
  margin-top: 10px !important;
  margin-left: 0px !important;
  border-radius: 0% !important;
}

@media screen and (min-width: 0px) and (max-width: 900px) {
  .form-control-1 {
    // width: 94% !important;
  }

  .full-page-background-producer {
    // overflow-y: scroll;
    height: auto;
    padding: 1rem;
    max-height: none !important;

    .container {
      max-width: none;

      .background-producer {
        padding: 1rem !important;
        padding-right: 0rem !important;
        padding-left: 0rem !important;
      }
    }
  }
}