.full-page-background-welcome {
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #101010;
  // background: url("../images/ott.png");
  border: 2px solid black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 88vh;
  max-height: 80vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    background-color: #101010;
    // margin-left: 25%;
    // margin-top: 5%;
    // width: fit-content;

    .background {
      padding: 160px 60px 60px 60px;
      gap: 60px;
      align-self: stretch;
    }
  }
}

.cards {
  padding: 5%;
}

.css-1sir9cm-MuiPaper-root {
  background-color: #1e1e1e !important;
}

.css-i9gxme {
  margin-top: 0px;
}

.welcome-header {
  color: #fff !important;
  text-align: center !important;
  font-variant-numeric: lining-nums proportional-nums !important;
  font-family: "DM Sans" !important;
  font-size: 38px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  margin-top: 12px !important;
}

.sub-title {
  color: #fff;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 768px) {
  .full-page-background-welcome {
    height: auto;
    padding: 0 10px;
    max-height: fit-content;
  }
}

@media (max-width: 480px) {
  .full-page-background-welcome {
    height: auto;
    padding: 0 10px;
    max-height: fit-content;
  }
}
