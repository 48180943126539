/* MovieUploadComponent.css */
body {
  background-color: black;
  color: white;
}

.card {
  background-color: black;
  color: white;
  margin-bottom: 20px;
  padding: 20px 30px 20px 30px;
}

.card_top_heading {
  background-color: black;
  color: white;
  margin-bottom: 20px;
  padding: 20px 30px 0px 30px;
}

.movie-info-card {
  background-color: #1e1e1e;
}

.upload-card {
  background-color: black;
  color: white;
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  cursor: pointer;
  border-radius: 10px !important;
}

.upload-card-icon {
  font-size: 50px;
}

.upload-card-text {
  color: #fed530;
}

.text-field {
  .MuiInputBase-root {
    color: white;
    height: 50px;
  }
  .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }
    &:hover fieldset {
      border-color: #fed530;
    }
    &.Mui-focused fieldset {
      border-color: #fed530;
    }
  }
  .MuiInputLabel-root {
    color: white;
    &.Mui-focused {
      color: #fed530;
    }
  }
}

// .text-field input::placeholder {
//   color: white !important;
// }

// .text-field textarea::placeholder {
//   color: white !important;
// }

// .text-field .MuiOutlinedInput-root {
//   background-color: black;
//   border-radius: 8px !important;
// }

// .text-field input::placeholder {
//   color: white !important;
// }

// .text-field textarea::placeholder {
//   color: white !important;
// }

.custom-dropdown-toggle::after {
  display: none !important;
}

.custom-dropdown-toggle {
  border: 1px solid white;
}

.custom-dropdown-toggle:hover {
  border: 1px solid #fed530;
}

.dropdown-arrow {
  margin-left: auto;
}

.para {
  padding-bottom: 1rem;
}

input {
  color: #fff !important;
  text-align: left !important;
  // background-color: #000 !important;
  height: 1.4375em;
  padding-left: 10px;
  border-radius: 10px !important;
}

.text-field {
  background-color: black;
  color: white !important;
  margin-bottom: 20px;
  border-radius: 10px !important;
  border: 2px solid gray !important;
}

.upload_field {
  background-color: black;
  color: white !important;
  margin-bottom: 20px;
  border-radius: 10px !important;
  border: 2px solid gray !important;
}

textarea {
  color: #fff !important;
}

.back-button {
  height: 44px;
  padding: 10px;
  border-radius: 32px;
  border: 1px solid #fed530 !important;
  background: #fed530 !important;
  color: #000 !important;
  font-weight: 600;
}
