.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #141414;
  color: #fff;
  padding: 30px 60px;
  bottom: 0;
  width: 100%;
}

.logo-image{
  height: 80px;
}

@media (max-width: 500px) {
  .footer {
    flex-direction: column !important;
    align-items: center;
    padding: 0 !important;
  }
  .logo-image{
    height: 60px !important;
  }
}


