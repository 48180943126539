.mobNum {
  color: #fff !important;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.16px;
}

.mob-input {
  border-radius: 45px !important;
  text-align: start;
  color: #fff !important;
}

.login .google {
  background-color: #fff !important;
  color: #313957 !important;
  height: 50px !important;
  width: -webkit-fill-available !important;
  margin-top: 20px !important;
  cursor: pointer !important;
  font-family: "DM Sans" !important;
  border: #fff !important;
  font-weight: 500 !important;
  border-radius: 12px !important;
}

img {
  width: -webkit-fill-available;
}

.css-a1xfks {
  position: relative;
}

.cardvalue {
  display: contents;

  .login-Header {
    color: #fed530;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "DM Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
    display: "flex";
    justify-content: "center";
    margin-top: 80px;
  }
}

.skip {
  float: right;
  opacity: 0.6;
  cursor: pointer;
  border-radius: 45px;
  background: #333;
  display: flex;
  padding: 10px 16px 10px 20px;
  align-items: center;
  gap: 6px;
}

.full-page-background-login {
  top: 0;
  left: 0;
  background-color: #000;
  z-index: -1;
  border: 2px solid black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: #151512;
  height: 88vh;
  max-height: 87vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;

    .background {
      border: none;
      height: fit-content;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      background-color: #111;
      padding: 36px 48px;
      border-radius: 16px;
      width: 38%;
    }
  }
}

.Continue-Button-login {
  background-color: #fed530 !important;
  color: #101828 !important;
  border: none !important;
  border-radius: 45px !important;
  cursor: pointer !important;
  font-size: 20px !important;
  font-family: "DM Sans" !important;
  font-weight: 500 !important;
  width: -webkit-fill-available !important;
  padding: 16px !important;
}

.cardvalue .skip {
  float: right;
  opacity: 0.6;
  cursor: pointer;
  border-radius: 45px;
  background: #333;
  display: flex;
  padding: 10px 16px 10px 20px;
  align-items: center;
  gap: 6px;
  border: #333;
  color: #e6e6e6;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (min-width: 0px) and (max-width: 900px) {
  .full-page-background-login {
    .container {
      width: fit-content;

      .background {
        padding: 1rem;
        width: 100%;
      }
      .react-tel-input .form-control {
        width: 96% !important;
      }
    }
  }
}

.cardvalue {
  display: contents;

  .info-Header {
    color: #fed530;
    text-align: start;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "DM Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
    display: "flex";
    justify-content: flex-start;
    margin-top: 10px;
  }
}

.uploadpara {
  color: #fed530;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.nameinput {
  text-align: start !important;
  background: #2e2e2e !important;
  border: 1px solid #2e2e2e !important;
  color: #fff !important;
  font-family: "DM Sans" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  border-radius: 0 !important;
}

.form-control {
  background: #333 !important;
  padding: 10px 24px 10px 16px;
  // width: 388px !important;
  width: 100% !important;
  height: 52px !important;
  color: #fff !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: none !important;
  text-align: start !important;
}

.react-tel-input {
  margin-top: 10px !important;
  margin-left: 10px !important;
}

.flag-dropdown {
  background-color: #333 !important;
  border-radius: 57px !important;
  border: none !important;
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

// .flag-dropdown:hover {
//   background-color: #333 !important;
//   border-radius: 57px !important;
//   border: none !important;
// }
