.noImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  .noimageText {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    margin-bottom: 0.35em;
  }
}

.account-tabs .MuiTab-root.custom-tab.Mui-disabled {
  background: none !important; /* No background for disabled tabs */
  color: inherit !important;   /* Inherit text color */
  cursor: default;             /* Change cursor to indicate it's disabled */
}
