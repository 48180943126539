.Language-main {
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #101010;
  border: 2px solid black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  min-height: 100vh;
  padding: 30px 60px;

  .main-container {
    background-color: #101010;
    align-items: center;
    padding: 20px 0;
  }

  .headings.conatiner {
    background-color: #101010;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top: 5%;
  }

  .Language-header-title {
    .Language-header {
      color: #fff;
      font-family: "DM Sans";
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .Language-sub-title {
    .sub-title {
      color: #fff;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      width: 50%;
    }
  }

  .custom-card {
    height: 100%;
    border: 2px solid transparent;
    cursor: pointer;
    transition: border 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &.selected {
      border: 2px solid yellow;
      border-radius: 8px;
    }

    .card-background {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 0px;
      position: relative;
      padding: 150px;
      padding: 45%; /* Adjust the padding as needed */
      box-sizing: border-box;
      &.selected {
        border-radius: 5px;
      }
      .card-body {
        text-align: center;
      }
    }
  }

  .Language-continue {
    margin-top: 12px;
    text-align: center;
  }

  .Continue-Button-intropage {
    border-radius: 40px;
    background: #fed530;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 16px 30px;
    justify-content: center;
    gap: 8px;
    color: #1e1e1e;
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 500;
  }
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .Language-main {
    padding: 20px 30px;
  }

  .Language-sub-title .sub-title {
    width: 100%;
    text-align: center;
  }

  .custom-card .card-background {
    padding: 100px; // Adjust padding for smaller screens
  }

  .Continue-Button-intropage {
    width: 100%;
    padding: 12px 20px;
    
  }
}

@media (max-width: 480px) {
  .Language-main {
    padding: 10px 20px;
  }

  .Language-header-title .Language-header {
    font-size: 24px;
    text-align: center;
  }

  .Language-sub-title .sub-title {
    font-size: 14px;
  }

  .custom-card .card-background {
    padding: 50px; // Adjust padding for smaller screens
  }
}

@media (max-width: 500px) {
  .Language-main {
    .Language-header-title {
      .Language-header {
        font-size: 24px;
        margin-bottom: 1rem;
      }
    }
    .Continue-Button-intropage {
      height: 16px;
      margin-bottom: 1rem;
    }
  }
}
