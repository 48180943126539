.account-container {
  background-color: black;
  color: white;
  padding: 16px;
  overflow: hidden;
}

.account-header {
  width: 100%;
  border-bottom: 1px solid gray;
  margin-bottom: 16px;
  text-align: start;
  display: flex;
  justify-content: space-between;
}

.account-title {
  margin-bottom: 8px;
}

.account-tabs {
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTab-root {
    color: white;
    text-transform: none;
    justify-content: space-between;
    padding-left: 3;
    padding-right: 6;
    padding-top: 0;
    padding-bottom: 0;
    width: 200px;

    .MuiTab-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .Mui-selected {
    background-color: #fed530;
    color: black !important;
    border-radius: 45px;
    min-height: 44px;
    width: 200px;
  }
}

.profile-tab-content {
  padding: 16px;
  border-radius: 8px;
}

.profile-form {
  padding: 16px;
  border: 1px solid #1e1e1e;
  background-color: #1e1e1e;
  border-radius: 8px;
}

.custom-textfield {
  .MuiInputBase-root {
    color: white;
    height: 50px;
  }
  .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }
    &:hover fieldset {
      border-color: #fed530;
    }
    &.Mui-focused fieldset {
      border-color: #fed530;
    }
  }
  .MuiInputLabel-root {
    color: white;
    &.Mui-focused {
      color: #fed530;
    }
  }
}

.custom-textfield-desc {
  .MuiInputBase-root {
    color: white;
    // height: 50px;
  }
  .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }
    &:hover fieldset {
      border-color: #fed530;
    }
    &.Mui-focused fieldset {
      border-color: #fed530;
    }
  }
  .MuiInputLabel-root {
    color: white;
    &.Mui-focused {
      color: #fed530;
    }
  }
}

.hidden-indicator {
  display: none;
}

.mobile {
  display: none !important;
}
@media (max-width: 500px) {
 .account-tabs-grid{
  display: none !important;
 }
  .mobile {
    display: block !important;
  }
}
