

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-placeholder {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #444;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.upload-placeholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-text {
  color: #FED530;
  margin-top: 8px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cropper-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.cropper-circular img {
  border-radius: 50%;
}
