.watch-history-container {
  display: flex;
  flex-direction: column; /* Stack rows vertically */
  color: white;
}

.WatchHistory-heading {
  margin-bottom: 2rem;
}

.image-row {
  display: flex;
  gap: 10px; /* Space between images */
  margin-bottom: 10px; /* Space between rows */
  flex-wrap: wrap; /* Allows images to wrap to the next line */
  justify-content: center; /* Center images horizontally */
}

.watch-history-image {
  height: 200px; /* Default height for smaller screens */
  width: 130px; /* Default width for smaller screens */
  object-fit: cover; /* Ensure images cover the area without distortion */
}

@media (min-width: 600px) {
  .watch-history-image {
    height: 300px; /* Adjusted height for medium screens */
    width: 200px; /* Adjusted width for medium screens */
  }
}

@media (min-width: 900px) {
  .watch-history-image {
    height: 400px; /* Height for larger screens */
    width: 260px; /* Width for larger screens */
  }
}
