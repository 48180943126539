.Slider {
  cursor: pointer;
  background-color: #101010;
 
  .mainSlider-header {
    padding: 20px;
 
    .header-text {
      color: var(--Text-White, #fff);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: "DM Sans";
      // font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 10px;
      text-align: left;
    }
 
    .seemore-btn {
      color: #e6e6e6;
      font-family: "DM Sans";
      // font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
    }
  }
}
 
.slider {
  position: relative;
  padding: 0px;
  margin-right: 8px;
 
  img {
    display: block;
    width: 260px;
    height: 400px;
    margin: 0;
    padding: 0;
    object-fit: cover;
    border-radius: 0.25rem;
  }
 
  .image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 260px;
    // margin-left: 8px;
    border-radius: 0.25rem;
    height: 400px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 1rem;
    box-sizing: border-box;
  }
 
  &:hover .image-overlay {
    opacity: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000000 68.75%);
  }
}
 
.react-multi-carousel-item {
  flex: 0 0 auto;
  width: 260px !important;
  margin-right: 16px; /* Add gap between carousel items */
  box-sizing: border-box;
}
 
/* Description truncation styles */
.description {
  position: relative;
  overflow: hidden; /* Hide overflow text */
}
 
.text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Show only 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
 
.text.full {
  display: block;
  -webkit-line-clamp: unset; /* Remove line clamp */
  overflow: visible; /* Show all text */
}
 
.see-more {
  display: block;
  cursor: pointer;
  color: #e6e6e6;
  font-weight: bold;
}
 


@media (max-width: 500px) {
  .mainSlider-header {
    padding: 1rem !important;
  }
}
