.custom-carousel {
  position: relative;

  .custom-controls {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 40px; // Adjust to place above indicators
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;

    .custom-control-prev,
    .custom-control-next {
      background: none;
      border: none;
      border-radius: 50%;
      padding: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: rgba(0, 0, 0, 0.7);
      }
      @media (max-width: 500px) {
        display: none;
      }
    }
    .custom-controls-image {
      height: 40px !important;
      object-fit: contain;
      width: 40px;
      border-radius: 50%;
    }
    .custom-control-prev {
      margin-right: 20px;
    }

    .custom-control-next {
      margin-left: 20px;
    }
  }

  // .carousel-indicators {
  //   display: flex;
  //   justify-content: center;
  //   position: absolute;
  //   bottom: 20px; // Adjust as necessary
  //   left: 50%;
  //   transform: translateX(-50%);
  //   z-index: 10;

  .indicator {
    background-color: #fff;
    border: none;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
    &.active {
      background-color: #000;
    }
  }
  // }

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 2%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
  }

  .carousel-button {
    display: flex;

    .buybutton {
      background-color: #fed530;
      border-radius: 40px;
      color: #1e1e1e;
      padding: 15px;
    }
    .play-buton {
      background-color: #fed530;
      border-radius: 40px;
      .play-buton-image {
        height: 40px;
      }
    }

    .morebutton {
      background-color: #161616;
      margin-left: 2%;
      border-radius: 40px;
      color: #fff;
    }
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}

.carousel-item {
  position: relative;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    opacity: 0.6;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000000 68.75%);
  }
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  color: #fed530;
}

@media (max-width: 500px) {
  .carousel-button {
    display: flex;

    .buybutton {
      background-color: #fed530;
      border-radius: 40px;
      color: #1e1e1e;
      padding: 15px;
      font-size: 12px;
    }
    .play-buton {
      background-color: #fed530;
      border-radius: 40px;
      font-size: 12px;
      .play-buton-image {
        height: 20px;
      }
    }

    .morebutton {
      background-color: #161616;
      margin-left: 2%;
      border-radius: 40px;
      color: #fff;
      font-size: 12px;
    }
  }
}
