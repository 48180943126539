.image-container-watchlist-page {
  position: relative;
  width: 100%;
  height: 400px;
  cursor: pointer;
}

.image-watchlist-page {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay-watchlist-page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: rgba(
    0,
    0,
    0,
    0.7
  ); /* Added a solid color with opacity for better contrast */
  display: flex;
  justify-content: left;
  align-items: flex-end;
  padding: 10px;
}

.image-container-watchlist-page:hover .overlay-watchlist-page {
  opacity: 1;
}

.text-watchlist-page {
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 10px; /* Added padding for better readability */
}

.UA-c {
  border: 1px solid white;
  padding: 0.3rem;
  display: inline-block;
}
