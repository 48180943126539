// src/scss/MembershipPlans.scss
.membership-plans {
  color: white;

  .current-plan {
    display: flex;
    align-items: center;
    height: 100px;
    background-color: #181818;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
    margin-top: 1rem;

    .plan-icon {
      height: 40px;
      width: 40px;
    }

    .plan-info {
      margin-left: 10px;

      .plan-label {
        background-color: #fed530;
        color: black;
        font-weight: bold;
        padding: 2px 4px;
        border-radius: 2px;
        display: inline-block;
      }

      .plan-title {
        margin-top: 5px;
        font-weight: bold;
      }
    }
  }

  .available-plans {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    @media (max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .plan-card {
      height: 279px;
      background-color: #181818;
      margin-bottom: 20px;
      padding: 20px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .plan-icon {
        height: 40px;
        width: 40px;
      }

      .plan-price {
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px;
      }

      .divider {
        margin: 10px 0;
        background-color: #ffffff;
      }

      .plan-title {
        font-weight: bold;
      }

      .plan-description {
        margin-top: 10px;
        flex-grow: 1;
      }

      .plan-button {
        background-color: #fed530;
        color: black;
        height: 40px;
        border-radius: 45px;
        text-transform: none;

        &.current {
          background-color: black;
          color: white;
          // border: 1px solid #fed530;
        }
      }
    }
  }
}

.MembershipPlans-sub_heading {
  margin: 2.5rem 0 2rem 0;
}
