.Slider {
  padding-left: 3rem;
  padding-right: 3rem;
  .mainSlider-header {
  }

  .parent .react-multi-carousel-list {
    height: auto !important;
  }

  .react-multi-carousel-arrow--left {
    position: absolute;
    top: 50%;
    left: -25px; /* Adjust this value to position the left arrow */
    transform: translateY(-50%);
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .react-multi-carousel-arrow--right {
    position: absolute;
    top: 50%;
    right: -25px; /* Adjust this value to position the right arrow */
    transform: translateY(-50%);
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .UA-c {
    border: 1px solid white;
    padding: 0.3rem;
    display: inline-block;
  }

  .overlay-content {
    padding: 1rem 0.5rem 1.5rem 0rem;
  }

  .header-text {
    // font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    padding: 20px 70px;
  }

  .movie-songs {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-inline: 20px;
    padding: 20px 50px;
  }

  .movie-song {
    width: 400px;
    height: 200px;
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    gap: 2;

    .image-wrapper {
      position: relative;
      width: 95%;
      height: 100%;

      img {
        height: 400px;
        display: block;
        transition: transform 0.3s ease;
        object-fit: cover;
      }

      &:hover img {
        // transform: scale(1.1);
      }

      .image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        // width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        opacity: 0.8;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.1) 0%,
          #000000 68.75%
        );
        transition: opacity 0.3s ease;
        // margin-top: 2rem;
      }

      &:hover .image-overlay {
        opacity: 1;
      }

      .caption {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, #000 86%),
          lightgray 50% / cover no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 10px;
        color: white;
        height: 200px;

        h3 {
          margin: 0;
          font-size: 20px;
        }

        p {
          margin: 0;
          font-size: 14px;
        }

        .duration {
          font-size: 12px;
        }
      }
    }
  }

  .icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .icon-container .left-icons {
    display: flex;
    gap: 10px;
  }

  .icon-container .right-icon {
    margin-left: auto;
  }

  .icon-text-container {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .icon-text-container img {
    // margin-right: 8px;
  }
}

@media (max-width: 500px) {
  .Slider {
    .mainSlider-header {
      .header-text {
        padding: 1rem 0rem !important;
      }
    }
    padding: 1rem !important;
  }
  .movie-songs {
    padding: 1rem !important;
    margin: 0 !important;
  }
  .header-text {
    padding: 1rem !important;
  }
}
